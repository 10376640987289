import React, { useState } from "react";
import { useAuth } from "../../store/contexts/auth";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Button, Card, Form, Input, message, Typography } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import RegisterImage from "../../assets/register.png";

const { Title, Text } = Typography;

const Register = () => {
  const navigate = useNavigate();
  const { register, user } = useAuth();
  const [loading, setLoading] = useState(false);

  if (user) return <Navigate to="/" />;

  const onFinish = async (values) => {
    console.log(values);
    setLoading(true);
    try {
      const res = await register(values);
      if (res.status === 200) {
        message.success("Registration successful! Redirecting to login...");
        navigate("/login");
      } else {
        throw new Error("Something went wrong, please try again.");
      }
    } catch (error) {
      message.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen p-4">
      <Card className="w-full max-w-md shadow-lg">
        <div className="flex justify-center mb-4">
          <img src={RegisterImage} alt="Register" className="h-40" />
        </div>
        <Title level={3} className="text-center">
          Register
        </Title>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Please enter your name!" },
              { min: 3, message: "Name must be at least 3 characters long!" },
            ]}
          >
            <Input placeholder="Enter your name" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter your email!" },
              { type: "email", message: "Enter a valid email!" },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              { required: true, message: "Please enter your password!" },
              { min: 6, message: "Password must be at least 6 characters!" },
              {
                pattern: /^(?=.*[A-Z])(?=.*\d)/,
                message:
                  "Password must contain at least one uppercase letter and one number!",
              },
            ]}
          >
            <Input.Password
              placeholder="Enter your password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={loading}>
              {loading ? "Please wait..." : "REGISTER"}
            </Button>
          </Form.Item>
        </Form>

        <Text className="block text-center">
          Already have an account?{" "}
          <Link to="/login" className="text-primary">
            Login here
          </Link>
        </Text>
      </Card>
    </div>
  );
};

export default Register;
