import {
  Button,
  Flex,
  Form,
  Image,
  Input,
  Modal,
  Typography,
  message,
  Upload,
  Skeleton,
  Popconfirm,
  Collapse,
  Card,
  Divider,
} from "antd";
import React, { useState } from "react";
import { useAuth } from "../../store/contexts/auth";
import {
  GithubOutlined,
  LinkedinOutlined,
  LoadingOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import classNames from "classnames";
import { AddEditCategory } from "../category/addEdit";
import { deleteProfile, getUser, updateProfile } from "../../constant/user";
import { beforeUpload, getBase64 } from "../../utils/helper";

const Profile = () => {
  const { logout, token } = useAuth();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [user, setUser] = useState({ name: "", email: "" });
  const [phone, setPhone] = useState();
  const [budget, setBudget] = useState(null);
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [github, setGithub] = useState("");

  const { isLoading, data: loggedInUser } = useQuery({
    queryKey: ["LOGGED_ID_USER"],
    queryFn: getUser,
  });
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (payload) => updateProfile(payload),
    onSuccess: (data) => {
      message.success("Profile updated!");
      queryClient.refetchQueries({ queryKey: ["LOGGED_ID_USER"] });
      setOpenEditModal(false);
    },
    onError: (err) => {
      message.error("Something went wrong.");
    },
  });

  const { mutate: deleteProfileMutation, isPending: deletingIsPending } =
    useMutation({
      mutationFn: () => deleteProfile(),
      onSuccess: (data) => {
        message.success("Profile deleted!");
        logout();
      },
      onError: (err) => {
        message.error("Something went wrong.");
      },
    });

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
      queryClient.refetchQueries({ queryKey: ["LOGGED_ID_USER"] });
    }
  };

  useEffect(() => {
    if (!loggedInUser) return;
    setUser({
      name: loggedInUser.name,
      email: loggedInUser.email,
    });
    if (loggedInUser.phone) {
      setPhone(loggedInUser.phone);
    }
    if (loggedInUser.budget) {
      setBudget(loggedInUser.budget);
    }
    if (loggedInUser.github) {
      setGithub(loggedInUser.github);
    }
    if (loggedInUser.linkedin) {
      setLinkedin(loggedInUser.linkedin);
    }
    if (loggedInUser.twitter) {
      setTwitter(loggedInUser.twitter);
    }
  }, [loggedInUser]);

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const items = [
    {
      key: "1",
      label: "Advance Features",
      children: (
        <Flex vertical>
          <Flex justify="space-between" align="center">
            <Flex vertical>
              <Typography.Text>Deactivate account</Typography.Text>
              <Typography.Text type="secondary">
                Reactivate your account anytime
              </Typography.Text>
            </Flex>

            <Button type="primary">Deactivate Account</Button>
          </Flex>
          <Divider />
          <Flex justify="space-between" align="center">
            <Flex vertical>
              <Typography.Text>Delete account</Typography.Text>
              <Typography.Text type="secondary">
                This will delete your account and all its data, your won't be
                able to recover it.
              </Typography.Text>
            </Flex>
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this account?"
              onConfirm={() => {
                deleteProfileMutation();
              }}
              okText="Yes"
              placement="left"
              cancelText="No"
              disabled={deletingIsPending}
            >
              <Button type="primary" danger>
                Delete Account
              </Button>
            </Popconfirm>
          </Flex>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <div>
        <>
          {isLoading ? (
            <Flex
              vertical
              align="center"
              className="mx-4 mb-4 p-4 bg-muted rounded-lg overflow-hidden border gap-4"
            >
              <Skeleton.Image active />
              <Flex vertical gap={2}>
                <Skeleton.Input active />
                <Skeleton.Input active />
              </Flex>
            </Flex>
          ) : (
            <Card className="mx-4 mb-4">
              <Flex justify="center" align="center">
                <Image
                  height={100}
                  width={100}
                  src={loggedInUser?.photoUrl}
                  fallback={`${process.env.REACT_APP_SERVER_URL}/uploads/profile/dummy.png`}
                  style={{ borderRadius: "100%" }}
                />
              </Flex>
              <Flex justify="center" align="center" vertical className="mt-4">
                <h3 className="font-bold text-lg">{loggedInUser?.name}</h3>
                {loggedInUser?.email && (
                  <h4 className="text-gray-500 flex items-center gap-1">
                    <MailOutlined /> {loggedInUser?.email}
                  </h4>
                )}
                {loggedInUser?.phone && (
                  <h4 className="text-gray-500 flex items-center gap-1">
                    <PhoneOutlined /> {loggedInUser?.phone}
                  </h4>
                )}
                {loggedInUser?.twitter && (
                  <h4 className="text-gray-500 flex items-center gap-1">
                    <TwitterOutlined />{" "}
                    <a
                      href={loggedInUser?.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {loggedInUser?.twitter}
                    </a>
                  </h4>
                )}
                {loggedInUser?.linkedin && (
                  <h4 className="text-gray-500 flex items-center gap-1">
                    <LinkedinOutlined />{" "}
                    <a
                      href={loggedInUser?.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {loggedInUser?.linkedin}
                    </a>
                  </h4>
                )}
                {loggedInUser?.github && (
                  <h4 className="text-gray-500 flex items-center gap-1">
                    <GithubOutlined />{" "}
                    <a
                      href={loggedInUser?.github}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {loggedInUser?.github}
                    </a>
                  </h4>
                )}
              </Flex>
            </Card>
          )}
        </>
        <Flex className="items-center justify-between p-4 bg-muted border rounded-lg mx-4 mb-4 gap-4">
          <Typography.Text>Edit your profile details</Typography.Text>
          <Button
            className="bg-foreground"
            type="primary"
            onClick={() => {
              setOpenEditModal(true);
            }}
          >
            Edit Profile
          </Button>
        </Flex>{" "}
        <Flex className="items-center justify-between p-4 bg-muted border rounded-lg mx-4 mb-4 gap-4">
          <Typography.Text className="block mb-2">
            Update profile picture
          </Typography.Text>
          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            action={`${process.env.REACT_APP_SERVER_URL}/users/image-upload`}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            headers={{
              Authorization: `Bearer ${token}`,
            }}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </Flex>
        <Flex className="items-center justify-between p-4 bg-muted border rounded-lg mx-4 mb-4 gap-4">
          <Flex className="flex-col">
            <Typography.Text>Categories</Typography.Text>
            <Typography.Text type="secondary" italic>
              Categories will show up when you add an expense and select its
              category.
            </Typography.Text>
          </Flex>
          <Button
            type="primary"
            onClick={() => {
              setOpenCategoryModal(true);
            }}
          >
            View Categories
          </Button>
        </Flex>
        <Flex className="items-center justify-between p-4 bg-muted border rounded-lg mx-4 mb-4 gap-4">
          <Typography.Text>Logout from app</Typography.Text>
          <Button type="primary" danger onClick={logout}>
            Logout
          </Button>
        </Flex>
        <div className="px-4">
          <Collapse items={items} />
        </div>
      </div>
      <Modal
        title="Update profile"
        open={openEditModal}
        onCancel={() => {
          setOpenEditModal(false);
        }}
        footer={
          <Flex justify="flex-end" gap={8}>
            <Button
              type="default"
              onClick={() => {
                setOpenEditModal(false);
              }}
            >
              Close
            </Button>
            <Button
              type="primary"
              className={classNames({
                "disabled-btn": isPending,
              })}
              disabled={isPending}
              onClick={() => {
                mutate({ ...user, phone, budget, twitter, linkedin, github });
              }}
            >
              {isPending ? "Updating..." : "Update"}
            </Button>
          </Flex>
        }
      >
        <Form layout="vertical">
          <Form.Item label="Name">
            <Input
              placeholder="Full name"
              value={user.name}
              onChange={(ev) => {
                setUser({ ...user, name: ev.target.value });
              }}
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              placeholder="email@provider.com"
              value={user.email}
              onChange={(ev) => {
                setUser({ ...user, email: ev.target.value });
              }}
            />
          </Form.Item>
          <Form.Item label="Phone">
            <PhoneInput
              className="border p-1 rounded-lg hover:border-primary"
              flags={flags}
              defaultCountry="IN"
              placeholder="Enter phone number"
              value={phone}
              onChange={setPhone}
            />
          </Form.Item>
          <Form.Item label="Set Budget (Monthly)">
            <Input
              type="number"
              placeholder="100000"
              className="border p-1 rounded-lg hover:border-primary"
              value={budget}
              onChange={(ev) => {
                setBudget(ev.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label="Twitter">
            <Input
              placeholder="Enter Twitter URL"
              value={twitter}
              onChange={(ev) => setTwitter(ev.target.value)}
            />
          </Form.Item>
          <Form.Item label="LinkedIn">
            <Input
              placeholder="Enter LinkedIn URL"
              value={linkedin}
              onChange={(ev) => setLinkedin(ev.target.value)}
            />
          </Form.Item>
          <Form.Item label="Github">
            <Input
              placeholder="Enter Github URL"
              value={github}
              onChange={(ev) => setGithub(ev.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
      <AddEditCategory
        openCategoryModal={openCategoryModal}
        setOpenCategoryModal={setOpenCategoryModal}
      />
    </>
  );
};

export default Profile;
