import { Navigate } from "react-router-dom";
import { useAuth } from "../../store/contexts/auth";
import Header from "../layout/header";
import Footer from "../layout/Footer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "antd";
export const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  const queryClient = new QueryClient();

  if (user?.id) {
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#333232",
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Header />
          <main className="mt-[76px] mb-[84px]">{children}</main>
          <Footer />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ConfigProvider>
    );
  } else {
    return <Navigate to="/login" />;
  }
};
