import React from "react";
import { Avatar, Flex, Typography, Dropdown, Menu } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../store/contexts/auth";

const Header = () => {
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const menuItems = (
    <Menu>
      <Menu.Item
        key="profile"
        icon={<UserOutlined />}
        onClick={() => navigate("/auth")}
      >
        Profile
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu> // Delete all expenses for the user
  );

  return (
    <Flex className="fixed top-0 left-0 w-full h-16 bg-white items-center justify-between px-4 z-50">
      <Flex align="center" gap={8}>
        <Typography.Text>
          Welcome!{" "}
          <Typography.Text type="success" strong>
            {user?.name?.split(" ")[0]}
          </Typography.Text>
        </Typography.Text>
      </Flex>
      <Dropdown overlay={menuItems} trigger={["click"]}>
        <Avatar
          size="large"
          src={`${process.env.REACT_APP_SERVER_URL}/uploads/profile/dummy.png`}
          style={{ cursor: "pointer" }}
        />
      </Dropdown>
    </Flex>
  );
};

export default Header;
