import Axios from "../config/axios_lts";

const BASE_URL = "expenses";

export const getExpense = async (expId) => {
  const { data } = await Axios.get(`${BASE_URL}/${expId}`);
  return data;
};

export const fetchMonthlyExpenses = async () => {
  const { data } = await Axios.get(`${BASE_URL}/history`);
  return data;
};

export const deleteExpense = async (expId) => {
  const { data } = await Axios.delete(`${BASE_URL}/${expId}`);
  return data;
};

export const getExpenses = async () => {
  const { data } = await Axios.get(BASE_URL);
  return data;
};

export const createExpense = async (payload) => {
  const { data } = await Axios.post(BASE_URL, payload);
  return data;
};

export const editExpense = async (payload, expId) => {
  const { data } = await Axios.put(`${BASE_URL}/${expId}`, payload);
  return data;
};

export const getMonthlyExpensesStats = async () => {
  const { data } = await Axios.get(`${BASE_URL}/monthly-summary`);
  return data;
};

export const getCategoryExpensesStats = async () => {
  const { data } = await Axios.get(`${BASE_URL}/category-summary`);
  return data;
};
