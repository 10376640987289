import React from "react";
import { Card, Collapse, Skeleton, Typography } from "antd";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { fetchMonthlyExpenses } from "../../constant/expense";

const History = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["monthlyExpenses"],
    queryFn: fetchMonthlyExpenses,
  });

  if (isLoading)
    return (
      <Card className="m-4">
        <Skeleton active />
      </Card>
    );
  if (error)
    return (
      <Card className="m-4">
        <Typography.Text type="danger">
          Error fetching data, Please try after sometime.
        </Typography.Text>
      </Card>
    );

  return (
    <div className="mt-20 max-w-2xl mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4 text-center">History</h2>
      <Collapse>
        {data.map(({ month, expenses }) => {
          const totalAmount = expenses.reduce(
            (sum, exp) => sum + exp.amount,
            0
          );
          const formattedMonth = moment(month, "YYYY-MM").format("MMMM YYYY");
          return (
            <Collapse.Panel
              key={month}
              header={
                <div className="flex justify-between w-full">
                  <span>{formattedMonth}</span>
                  <span className="font-semibold">
                    ₹{totalAmount.toFixed(2)}
                  </span>
                </div>
              }
            >
              <ul className="space-y-2">
                {expenses.map((expense, index) => (
                  <li
                    key={expense._id}
                    className={`py-2 ₹{
                      index !== expenses.length - 1 ? "border-b" : ""
                    }`}
                  >
                    <div className="flex justify-between">
                      <span>{expense.description}</span>
                      <span className="text-gray-600">
                        ₹{expense.amount.toFixed(2)}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default History;
