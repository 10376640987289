import { useState, useEffect } from "react";

export const useResponsiveHeight = (
  mobileHeight = 550,
  desktopHeight = 350
) => {
  const [height, setHeight] = useState(
    window.innerWidth < 768 ? mobileHeight : desktopHeight
  );

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerWidth < 768 ? mobileHeight : desktopHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [mobileHeight, desktopHeight]);

  return height;
};
