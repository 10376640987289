import moment from "moment";

export const groupExpensesByDate = (expenses) => {
  const groupedExpenses = {};
  expenses.forEach((expense) => {
    const dateKey = moment(expense.date).format("D MMM");
    if (!groupedExpenses[dateKey]) {
      groupedExpenses[dateKey] = [];
    }
    groupedExpenses[dateKey].push(expense);
  });
  return groupedExpenses;
};

export const totalAmountOfTheMonth = (expenses) => {
  const currentMonthExpenses = expenses.filter(
    (expense) => moment(expense.date).month() === moment().month()
  );
  return currentMonthExpenses.reduce(
    (total, expense) => total + expense.amount,
    0
  );
};

export const totalAmountOfTheDay = (expenses) => {
  return expenses.reduce((total, expense) => total + expense.amount, 0);
};
