import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Flex, Form, Input, message, Modal } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import CategoryList from "./category";
import { addCategory, updateCategory } from "../../constant/category";

export const AddEditCategory = ({
  openCategoryModal,
  setOpenCategoryModal,
}) => {
  const queryClient = useQueryClient();
  const [category, setCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if (selectedCategory) {
      setCategory(selectedCategory.name);
    } else {
      setCategory("");
    }
  }, [selectedCategory]);

  const { mutate: addMutate, isPending: isAdding } = useMutation({
    mutationFn: (payload) => addCategory(payload),
    onSuccess: () => {
      message.success("Category added successfully.");
      queryClient.invalidateQueries({ queryKey: ["CATEGORIES"] });
      resetForm();
    },
    onError: () => {
      message.error("Something went wrong.");
    },
  });

  const { mutate: updateMutate, isPending: isUpdating } = useMutation({
    mutationFn: (payload) => updateCategory(payload),
    onSuccess: () => {
      message.success("Category updated successfully.");
      queryClient.invalidateQueries({ queryKey: ["CATEGORIES"] });
      resetForm();
    },
    onError: () => {
      message.error("Something went wrong.");
    },
  });

  const resetForm = () => {
    setCategory("");
    setSelectedCategory(null);
    setOpenCategoryModal(false);
  };

  const handleSubmit = () => {
    if (!category.trim()) {
      message.warning("Category name cannot be empty.");
      return;
    }

    if (selectedCategory) {
      updateMutate({ id: selectedCategory._id, name: category });
    } else {
      addMutate({ name: category });
    }
  };

  return (
    <Modal
      title={selectedCategory ? "Edit Category" : "Add Category"}
      open={openCategoryModal}
      onCancel={resetForm}
      footer={
        <Flex justify="flex-end" gap={8}>
          <Button type="default" onClick={resetForm}>
            Close
          </Button>
          <Button
            type="primary"
            className={classNames({ "disabled-btn": isAdding || isUpdating })}
            disabled={isAdding || isUpdating || !category.trim()}
            onClick={handleSubmit}
          >
            {isAdding
              ? "Adding..."
              : isUpdating
              ? "Updating..."
              : selectedCategory
              ? "Update Category"
              : "Add Category"}
          </Button>
        </Flex>
      }
    >
      <CategoryList setSelectedCategory={setSelectedCategory} />
      <Form layout="vertical" className="mt-4">
        <Form.Item
          label={selectedCategory ? "Edit Category" : "Add New Category"}
        >
          <Input
            placeholder="Category Name"
            value={category}
            onChange={(ev) => setCategory(ev.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
