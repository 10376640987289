import React from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import ListSkeleton from "../ui/list_skeleton";
import { Expense } from "./single_expense";
import { getExpenses } from "../../constant/expense";
import {
  groupExpensesByDate,
  totalAmountOfTheDay,
  totalAmountOfTheMonth,
} from "../../utils/exp_helper";
import { getUser } from "../../constant/user";
import { Alert, Card, Collapse, Flex, Progress, Tag, Typography } from "antd";

const GetExpense = () => {
  const { data: loggedInUser } = useQuery({
    queryKey: ["LOGGED_ID_USER"],
    queryFn: getUser,
  });

  const { data: expenses = [], isLoading } = useQuery({
    queryKey: ["ALL_EXPENSES"],
    queryFn: () => getExpenses(),
  });

  const expensesByDate = groupExpensesByDate(expenses);
  const totalSpent = totalAmountOfTheMonth(expenses);
  const budget = loggedInUser?.budget || 0;
  const budgetUsed = budget > 0 ? (totalSpent / budget) * 100 : 0;
  const overBudgetAmount = totalSpent > budget ? totalSpent - budget : 0;

  const getProgressColor = () => {
    if (budgetUsed <= 50) return "lime";
    if (budgetUsed <= 70) return "gold";
    return "red";
  };

  if (expenses.length === 0 && !isLoading) {
    return (
      <Flex justify="center" align="center" className="h-full">
        <Card className="p-4">
          <Typography.Text strong type="success" italic>
            Hooray! No expenses this month 🎉
          </Typography.Text>
        </Card>
      </Flex>
    );
  }

  return (
    <>
      {overBudgetAmount > 0 && budget ? (
        <Alert
          message="Over Budget"
          description={`You have exceeded your budget by ₹${overBudgetAmount.toFixed(
            2
          )}`}
          type="error"
          closable
          className="mx-4 my-4"
        />
      ) : null}
      <Card className="mx-4">
        <div className="flex items-center justify-between">
          <h3>{moment().format("MMMM YYYY")}</h3>
          <h3>
            Total:{" "}
            <Tag color={getProgressColor()}>₹{totalSpent.toFixed(2)}</Tag>
          </h3>
        </div>
        {budget > 0 && (
          <Progress
            percent={Math.min(budgetUsed, 100)}
            showInfo={false}
            strokeColor={getProgressColor()}
            className="mt-2"
          />
        )}
      </Card>

      <div>
        {isLoading ? (
          <ListSkeleton length={4} moreClasses="pt-4 px-4" />
        ) : (
          <div className="p-4 flex flex-col gap-4">
            <Collapse
              accordion
              items={Object.entries(expensesByDate).map(([date, expenses]) => ({
                key: date,
                label: (
                  <div className="flex items-center justify-between w-full">
                    <strong>{moment(date).format("DD MMMM")}</strong>
                    <strong>Total: ₹{totalAmountOfTheDay(expenses)}</strong>
                  </div>
                ),
                children: (
                  <ul className="flex gap-2 flex-col bg-white p-4">
                    {expenses.map((expense, i) => (
                      <Expense key={expense._id} expense={expense} i={i} />
                    ))}
                  </ul>
                ),
              }))}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default GetExpense;
