import React, { createContext, useState, useContext, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

const AuthContext = createContext({
  user: null,
  login: () => {},
  register: () => {},
  logout: () => {},
  token: "",
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [token, setToken] = useState("");

  const getLoggedInUser = (user) => {
    return {
      name: user.name,
      id: user.id,
      email: user.email,
    };
  };

  useEffect(() => {
    const TOKEN = localStorage.getItem("token");
    if (TOKEN) {
      const decodedToken = jwt_decode(TOKEN);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp && decodedToken.exp < currentTime) {
        localStorage.clear();
        setUser(null);
      } else {
        const newUser = getLoggedInUser(decodedToken);
        setUser(newUser);
        setToken(TOKEN);
      }
    } else {
      setUser(null);
    }
    setLoading(false);
  }, [user?.id]);

  const login = async (data) => {
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/users/login`,
        {
          email: data.email,
          password: data.password,
        }
      );
      if (res.status === 200) {
        localStorage.setItem("token", res.data.token);
        const decodedToken = jwt_decode(res.data.token);
        const newUser = getLoggedInUser(decodedToken);
        if (res) {
          setUser(newUser);
          setToken(res.data.token);
          navigate("/");
          return res;
        }
      }
    } catch (error) {
      console.error("Login error:", error);
      return null;
    }
  };

  const register = async (formData) => {
    console.log(formData);
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_SERVER_URL}/users/register`,
        {
          name: formData.name,
          email: formData.email,
          password: formData.password,
        }
      );
      return res;
    } catch (error) {
      console.error("Registration error:", error);
      return null;
    }
  };

  const logout = () => {
    localStorage.clear();
    setUser(null);
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ login, logout, register, user, token }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
