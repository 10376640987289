import Axios from "../config/axios_lts";

const BASE_URL = "users";

export const deleteProfile = async () => {
  return (await Axios.delete(`${BASE_URL}`)).data;
};

export const getUser = async () => {
  return (await Axios.get(BASE_URL)).data;
};

export const updateProfile = async (payload) => {
  return (await Axios.put(BASE_URL, payload)).data;
};
