import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useQuery } from "@tanstack/react-query";
import {
  getCategoryExpensesStats,
  getMonthlyExpensesStats,
} from "../../constant/expense";
import { Card, Divider, Typography, Spin } from "antd";
import { useResponsiveHeight } from "./index.hook";

const { Title } = Typography;
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#FF4563"];

const monthNames = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Stats = () => {
  const height = useResponsiveHeight(550, 350);

  const { data: categoryResponse, isLoading: loadingCategory } = useQuery({
    queryKey: ["categoryExpenses"],
    queryFn: getCategoryExpensesStats,
  });

  const { data: monthlyData = [], isLoading: loadingMonthly } = useQuery({
    queryKey: ["monthlyExpenses"],
    queryFn: getMonthlyExpensesStats,
  });

  if (loadingCategory || loadingMonthly)
    return (
      <div className="flex justify-center items-center h-64">
        <Spin size="large" />
      </div>
    );

  const {
    month: prevMonth,
    year: prevYear,
    data: categoryData = [],
  } = categoryResponse || {};

  const prevMonthName =
    prevMonth && prevYear ? `${monthNames[prevMonth]} ${prevYear}` : "Unknown";

  const pieData = categoryData.map((item) => ({
    name: item.category || "Unknown",
    value: item.totalAmount || 0,
  }));

  const barData = monthlyData.map(({ month, year, totalAmount }) => ({
    month: month && year ? `${month} ${year}` : "Unknown",
    total: totalAmount || 0,
  }));

  return (
    <div className="max-w-5xl mx-auto p-4">
      <Title level={2} className="text-center">
        Expense Analytics
      </Title>

      <Card className="mb-6" title={`Expenses by Category (${prevMonthName})`}>
        <ResponsiveContainer width="100%" height={height}>
          <PieChart className="flex items-center justify-center">
            <Pie
              data={pieData}
              cx="50%"
              cy="50%"
              outerRadius={120}
              fill="#8884d8"
              dataKey="value"
            >
              {pieData.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip formatter={(value) => `₹${value.toLocaleString()}`} />
            <Legend layout="horizontal" />
          </PieChart>
        </ResponsiveContainer>
      </Card>
      <Divider>
        <Title level={4}>Yearly Expenses</Title>
      </Divider>

      <Card title="Monthly Expense Trend">
        <ResponsiveContainer width="100%" height={350}>
          <BarChart
            data={barData}
            margin={{ top: 10, right: 30, left: 0, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip formatter={(value) => `₹${value.toLocaleString()}`} />
            <Legend />
            <Bar dataKey="total" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

export default Stats;
