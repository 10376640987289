import Axios from "../config/axios_lts";

const BASE_URL = "/categories";

export const fetchCategories = async () => {
  const response = await Axios.get(BASE_URL);
  return response.data;
};

export const addCategory = async (payload) => {
  return (await Axios.post(BASE_URL, payload)).data;
};

export const updateCategory = async (payload) => {
  return (await Axios.put(BASE_URL, payload)).data;
};

export const editCategory = async (payload) => {
  return (await Axios.put(BASE_URL, payload)).data;
};

export const deleteCategory = async (id) => {
  return (await Axios.delete(`${BASE_URL}/${id}`)).data;
};
