import Axios from "../config/axios_lts";

const BASE_URL = "/note";

export const createNote = async (payload) => {
  return (await Axios.post(`${BASE_URL}/create`, payload)).data;
};

export const deleteNote = async (payload) => {
  return (await Axios.delete(`${BASE_URL}/${payload}`)).data;
};

export const getNotes = async () => {
  return (await Axios.get(BASE_URL)).data;
};
