import { List, Button, message } from "antd";
import { BsTrash } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteCategory, fetchCategories } from "../../constant/category";

const CategoryList = ({ setSelectedCategory }) => {
  const queryClient = useQueryClient();

  const { data: categories = [], isLoading } = useQuery({
    queryKey: ["CATEGORIES"],
    queryFn: fetchCategories,
  });

  const deleteMutation = useMutation({
    mutationFn: (payload) => deleteCategory(payload),
    onSuccess: (data) => {
      message.success("Successfully deleted category.");
      queryClient.invalidateQueries({
        queryKey: ["CATEGORIES"],
      });
    },
    onError: (err) => {
      message.error("Something went wrong.");
    },
  });

  return (
    <List
      bordered
      dataSource={categories}
      loading={isLoading}
      style={{
        maxHeight: "400px",
        overflowY: "auto",
      }}
      renderItem={(category) => (
        <List.Item
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              flex: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={category.name}
          >
            {category.name}
          </span>

          <div>
            <Button
              size="small"
              shape="circle"
              icon={<MdModeEdit />}
              onClick={() => {
                setSelectedCategory(category);
              }}
              type="primary"
              style={{ marginRight: 8 }}
            />
            <Button
              shape="circle"
              size="small"
              icon={<BsTrash />}
              loading={deleteMutation.isLoading}
              onClick={() => {
                deleteMutation.mutate(category._id);
              }}
              type="primary"
              disabled={deleteMutation.isLoading}
              danger
            />
          </div>
        </List.Item>
      )}
    />
  );
};

export default CategoryList;
